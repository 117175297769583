<template>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('186', { fieldname: $t('7').toLowerCase() })"
        :Helper="$t('345')"
        @confirm="fetchDeletePositon($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage($event)"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
      ></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <Header
    icon="positionOutline"
    :headerName="$t('7')"
    :btnName="$t('83')"
    :breadcrumbname="
      action == 'Insert' ? $t('83') : '' || action == 'Update' ? $t('388') : ''
    "
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true ? $t('200', { fieldname: $t('7').toLowerCase() }) : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListCampusClass="d-none"
    :Searchname="$t('82')"
    @onSearch="SearcPosition($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: false,
    }"
    @ConfigDisplay="reloadConfigDisplay()"
    ref="header"
  />
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listPositionFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnHaveDecision="false"
        :blnOpenFilter="blnOpenFilter"
        ref="filter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>

  <Table
    :progressScore="progress"
    :nametable="'7'"
    v-show="ListPosition.dataFields != null"
    v-if="!blnOpenform"
    :ListDataTable="ListPosition"
    firstSort="intPositionStatus"
    secondSort="strPositionNameEN"
    @edit="fetchGetPositionbyId($event)"
    @delete="fetchDeletePositon($event)"
    @ConfigDisplay="reloadConfigDisplay()"
    @onSaveConfigDisplay="onSaveConfigDisplay($event)"
    @editWorkTypeID="editWorkTypeID($event)"
    ref="table"
  />
  <!-- @ConfigDisplay="reloadConfigDisplay()" -->
  <div
    class="d-flex mt-sm-3 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 mb-0 w-100' : 'form-md mb-4'"
    >
      <div
        class="p-3 d-flex flex-column gap-5 justify-content-center"
        style="height: 414px"
        v-if="blnloading == true"
      >
        <div class="skeleton rounded" style="width: 100%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="skeleton rounded" style="width: 100%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="d-flex gap-3">
          <div class="skeleton rounded" style="width: 33%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 33%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 33%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
        </div>
        <div class="skeleton rounded" style="width: 20%; height: 40px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
      </div>
      <div v-show="blnloading == false">
        <Form
          v-if="blnOpenform"
          :typeSchema="typeSchema"
          :data="{ ...formdata, listworktype: listworktype }"
          @onInput="openform($event)"
          @Insert="fetchInsertPosition($event)"
          @Update="fetchUpdatePosition($event)"
          @delete="ConfirmDelete()"
          :action="action"
          :request="request"
          :inactive="formInactive"
          :msgalertDataDuplicate="msgalertDataDuplicate"
          ref="form"
        />
      </div>
    </div>
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('7').toLowerCase() })"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>

<script>
import AlertPopup from "@/components/alert/alertPopup.vue";
import Relate from "@/components/cards/relate.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import urlapi from "@/typescript/urlapi/url";
import apipostion from "@/typescript/urlapi/adminsystem/APIPosition";
import axios from "axios";
//import SortArray from "../typescript/sort/sorting";
// import PositionField from "../components/table/position.vue";
import Enumerable from "linq";
import Form from "@/components/form/form.vue";
import { typeSchema, request } from "@/components/constants/schemaAddPosition";
import AltStatus from "@/components/alert/alertStatus.vue";
import apiposition from "@/typescript/urlapi/adminsystem/APIPosition";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
export default {
  components: {
    Header,
    Table,
    Filter,
    Form,
    AltStatus,
    Relate,
    AlertPopup,
    // PositionField,
  },
  data() {
    return {
      blnloadError: false,
      blnloadConfirmDelet: false,
      blnloading: false,
      strSearch: "",
      listworktype: [],
      blnPopupConfirmDelete: false,
      formInactive: [],
      listRelate: [],
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      hover: false, //hover alt
      typeSchema: typeSchema, //
      timerEnabled: false,
      request: request,
      timerCount: 0,
      progress: 0,
      formdata: {},
      formdatadummy: {},
      positionId: null,
      blnOpenform: false,
      activeFiltersSelected: [],
      ListPosition: {},
      ListDummy: {},
      blnOpenFilter: false,
      intPinCount: 1,
      intWrongLogin: 0,
      strTel: null,
      strSearchField: ["strPositionNameEN", "strPositionNameTH"],
      updateAdsInventories: null,
      listPositionFilter: [
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "89" }, //active
                { id: 0, lang: "90" }, //inactive
              ],
            },
            { state: "intPositionStatus" },
          ],
        },
      ],
      errorMsg: "",
      workTypeID: 0,
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.fetchgetworktype();
    this.updateAdsInventories = setInterval(() => {
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 300);
  },
  watch: {
    blnOpenform: {
      deep: true,
      handler(val) {
        if (val == false) {
          this.typeSchema[0].group[0].group[1].group[0].component[0]["class"] =
            "mt-3";
        }
      },
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 10);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true,
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    ConfirmDelete() {
      // console.log("click button delete");
      this.blnError = false;
      this.$refs.AlertPopup.blnShowMSG = false;
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    async processLoadingData() {
      // if (this.$refs.header != null) {
      //   this.$refs.header.onClear();
      // }
      await this.fetchListPosition().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            // console.log("100");
            if (this.blnloadError == false) {
              this.progress = 100;
            }
          }, 1000);
        });
      });
    },
    //จัดการข้อมูลที่ได้รับจาก api เพื่อไปแสดงในตาราง
    async processData() {
      try {
        // console.log("process 2");
        //จัดเรียงข้อมูล
        setTimeout(() => {
          if (Array.isArray(this.ListPosition.data)) {
            this.ListPosition.data = Enumerable.from(this.ListPosition.data)
              .orderByDescending((r) => r.intPositionStatus)
              .thenBy((r) => r.strPositionNameEN.toLowerCase())
              .toArray();
          }
        }, 1000);
        //ตรวจสอบการตั้งค่าการแสดงผลในตาราง
        if (
          localStorage.getItem("SelectedListPosition") == null ||
          localStorage.getItem("SelectedListPosition") == "undefined"
        ) {
          localStorage.setItem(
            "SelectedListPosition",
            JSON.stringify(this.ListPosition.defaultField)
          );
        }

        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
            this.$refs.table.calculatePage(
              this.ListPosition.total,
              this.ListPosition.data
            );
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem("SelectedListPosition") != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListPosition"))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              this.$refs.table.setSelectedList(this.ListPosition.defaultField);
              localStorage.setItem(
                "SelectedListPosition",
                JSON.stringify(this.ListPosition.defaultField)
              );
            }
          }

          if (this.$refs.filter != null) {
            this.$refs.filter.Clear();
          }
        }, 1000);
        // console.log("ProcessData");
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    async fetchListPosition() {
      try {
        this.progress = 0;
        this.timerCount = 0;
        this.blnloadError = true;
        // console.log(urlapi + apipostion.getPositionList);
        var res = await axios.get(urlapi + apipostion.getPositionList);
        // console.log(res);
        if (res.data.code != 500) {
          this.progress += 10;
          this.ListDummy = res.data;
          this.ListPosition = res.data;
          this.blnloadError = false;
          // console.log("process 1");
        } else {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          this.progress = 0;
          setTimeout(() => {
            // this.blnAltLoadingData = false;
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        // this.blnAltLoadingData = true;
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        this.progress = 0;
        setTimeout(() => {
          // this.blnAltLoadingData = false;
          this.blnAltStatusunsucess = false;
        }, 5000);
        // throw new Error(error);
        console.log(error);
      }
    },
    async fetchUpdatePosition(event) {
      this.msgalertDataDuplicate = [];
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }

      let isEqual =
        JSON.stringify(this.formdatadummy) === JSON.stringify(event);

      event.strPositionWorkPeriod =
        (event.strPositionWorkPeriod.year.length != 1
          ? event.strPositionWorkPeriod.year
          : "0" + event.strPositionWorkPeriod.year) +
        (event.strPositionWorkPeriod.month.length != 1
          ? event.strPositionWorkPeriod.month
          : "0" + event.strPositionWorkPeriod.month) +
        (event.strPositionWorkPeriod.day.length != 1
          ? event.strPositionWorkPeriod.day
          : "0" + event.strPositionWorkPeriod.day);

      let strPositionNameEN = event.strPositionNameEN;
      let strPositionNameTH = event.strPositionNameTH;
      let strPositionWorkPeriod = event.strPositionWorkPeriod;
      let intPositionStatus = event.intPositionStatus;
      let intWorkTypeID = this.formdata.intWorkTypeID;
      let intWorkTypeIDNew =
        event.intWorkTypeID == this.formdata.intWorkTypeID
          ? 0
          : event.intWorkTypeID;
      // console.log(strPositionWorkPeriod);
      const formdata = {
        strPositionNameEN,
        strPositionNameTH,
        strPositionWorkPeriod,
        intPositionStatus,
        intWorkTypeID,
        intWorkTypeIDNew,
      };
      // console.log(formdata, this.formdata);
      if (isEqual == true) {
        await axios
          .get(urlapi + apipostion.checkusedstatus + this.positionId)
          .then((res) => {
            // console.log(res);
            if (
              res.data.message ==
              "Cannot edit ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              this.progress = 0;
              setTimeout(() => {
                this.blnnotedit = false;
              }, 5000);
              setTimeout(() => {
                this.processLoadingData();
              }, 2000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          })
          .catch(() => {
            this.blnAltLoadingData = true;
            setTimeout(() => {
              this.blnAltLoadingData = false;
            }, 2000);
          });
      }
      if (isEqual == false) {
        await axios
          .put(
            urlapi +
              apiposition.updatePosition +
              this.positionId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              "&Confirm=true",
            formdata
          )
          .then((res) => {
            // console.log(res);
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;

              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            } else if (res.data.edited == false) {
              if (res.data.positionnameEN == true) {
                let Message = { name: "strPositionNameEN", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              if (res.data.positionnameTH == true) {
                let Message = { name: "strPositionNameTH", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true; //ข้อมูลนี้ถูกลบไปแล้ว ไม่สามารถแก้ไขได้
                setTimeout(() => {
                  this.blnnotedit = false;
                }, 5000);

                this.progress = 0;
                setTimeout(() => {
                  this.processLoadingData();
                }, 1000);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                }, 5000);
                this.progress = 0;
                setTimeout(() => {
                  this.processLoadingData();
                }, 1000);
              } else {
                // console.log(res.data);
                this.blnAltStatusunsucess = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                setTimeout(() => {
                  this.blnAltStatusunsucess = false;
                }, 5000);
              }
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch(() => {
            this.blnAltLoadingData = true;
            setTimeout(() => {
              this.blnAltLoadingData = false;
            }, 2000);
          });
      }
    },
    editWorkTypeID(WorkTypeID) {
      this.workTypeID = WorkTypeID;
    },
    async fetchGetPositionbyId(positionId) {
      this.formInactive = [];
      // const workTypeID = this.ListPosition.data.find((ele) => {
      //   return ele.strID == positionId;
      // }).intWorkTypeID;
      var resRelate = await axios.get(
        urlapi + apipostion.checkusedstatus + positionId
      );
      this.listRelate = { ...resRelate.data };

      this.positionId = positionId;
      await axios
        .get(
          urlapi +
            apipostion.getPositionByID +
            positionId +
            "/" +
            this.workTypeID
        )

        .then((res) => {
          // console.log(res);
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            setTimeout(() => {
              this.blnnotedit = false;
              this.processLoadingData();
            }, 5000);
          } else {
            this.formInactive = [];
            this.action = "Update";
            this.formdata = res.data.data;
            const work = res.data.data.strPositionWorkPeriod;
            const strPositionWorkPeriod = work.toString().split("");

            //console.log(work.toString().length);
            if (work.toString().length == 6) {
              let WorkPeriod = {
                year: strPositionWorkPeriod[0] + strPositionWorkPeriod[1],
                month: strPositionWorkPeriod[2] + strPositionWorkPeriod[3],
                day: strPositionWorkPeriod[4] + strPositionWorkPeriod[5],
              };
              this.formdata["strPositionWorkPeriod"] = WorkPeriod;
              this.formdatadummy = { ...this.formdata };
              // console.log(this.formdata);
            } else if (work.toString().length == 5) {
              let WorkPeriod = {
                year: "0" + strPositionWorkPeriod[0],
                month: strPositionWorkPeriod[1] + strPositionWorkPeriod[2],
                day: strPositionWorkPeriod[3] + strPositionWorkPeriod[4],
              };
              this.formdata["strPositionWorkPeriod"] = WorkPeriod;
              this.formdatadummy = { ...this.formdata };
            } else if (work.toString().length == 1) {
              let WorkPeriod = {
                year: "00",
                month: "00",
                day: "0" + strPositionWorkPeriod[0],
              };
              this.formdata["strPositionWorkPeriod"] = WorkPeriod;
              this.formdatadummy = { ...this.formdata };
            }
            // console.log(work);

            try {
              if (this.listRelate.used == true) {
                var inactiveDelete = {
                  name: "blnDeleteinForm",
                  inactive: true,
                  tooltip: false,
                };
                this.formInactive.push(inactiveDelete);
              }
            } catch (error) {
              console.log(error);
            }
            var activeConfirm = {
              name: "intPositionStatus",
              blnConfirm: true,
            };

            this.formInactive.push(activeConfirm);
            this.blnOpenform = true;
          }
        });
    },

    async sprite(data) {
      try {
        console.log(data);
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    //ลบข้อมูลด้วย ID
    async fetchDeletePositon(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          // console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            // console.log(strUsername)
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.put(
                urlapi + apiposition.deletePosition + this.positionId,
                login
              );

              // console.log(res.data);
              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.blnAltStatus = true;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.progress = 0;

                //   //delay 1 sec
                setTimeout(() => {
                  if (this.$refs.header != undefined) {
                    this.$refs.header.onClear();
                  }
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
              } else if (res.data.candelete == false) {
                this.blnOpenform = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;

                //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                if (
                  res.data.message ==
                  "Cannot delete ,Please check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              } else {
                this.blnAltLoadingData = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;
                setTimeout(() => {
                  this.blnAltLoadingData = false;
                }, 5000);
              }
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        this.blnloadConfirmDelet = false;
        console.log(error);
      }
    },
    //ข้อมูลตำแหน่งงาน
    async fetchgetworktype() {
      const body = {
        intTypeID: 0,
        strID: "string",
        intParentStatus: 0,
        strLevel: "string",
      };
      try {
        let res = await axios.post(
          urlapi + apipostion.getControlFieldData + "ads_worktype",
          body
        );
        this.listworktype = res.data.data;

        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    //เพิ่มข้อมูลตำแหน่งงาน
    async fetchInsertPosition(data) {
      this.blnloading = true;
      // console.log(data);
      this.msgalertDataDuplicate = [];

      if (data.strPositionWorkPeriod.length != 6) {
        data.strPositionWorkPeriod =
          (data.strPositionWorkPeriod.year.length != 1
            ? data.strPositionWorkPeriod.year
            : "0" + data.strPositionWorkPeriod.year) +
          (data.strPositionWorkPeriod.month.length != 1
            ? data.strPositionWorkPeriod.month
            : "0" + data.strPositionWorkPeriod.month) +
          (data.strPositionWorkPeriod.day.length != 1
            ? data.strPositionWorkPeriod.day
            : "0" + data.strPositionWorkPeriod.day);
      }
      data["intWorkTypeIDNew"] = 0;
      // console.log(data);
      try {
        var res = await axios.post(
          urlapi +
            apipostion.insertPosition +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            "&Confirm=true",
          data
        );

        if (res.data.inserted == true) {
          //สั่งเปิดฟอร์ม
          this.blnOpenform = false;
          this.blnloading = false;

          //สั่งเปิดalt
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.processLoadingData();
          }, 1000);
        }
        if (res.data.inserted == false) {
          this.blnloading = false;
          // console.log(res.data.insert);
          if (res.data.positionnameEN == true) {
            let Message = { name: "strPositionNameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.positionnameTH == true) {
            let Message = { name: "strPositionNameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;

        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }

      this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);

      return true;
    },
    filter(activeFilters, ListDummy) {
      this.ListPosition.data = ListDummy.slice();
      for (var field in activeFilters) {
        this.ListPosition.data = this.ListPosition.data.filter(
          (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        );
      }
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListPosition.data.length,
          this.ListPosition.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;
      this.ListDummy.data = ListDummy;
    },
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;

      this.filter(activeFilters, this.ListDummy.data);
    },
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    SearcPosition(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event, this.strSearchField);
    },
    clean() {
      this.strSearch = "";
      this.SearcPosition("");
      //this.filteredData(this.activeFiltersSelected);
    },
    selectdata(event) {
      this.selectdatatype = event;
    },
    closeForm(event) {
      this.$emit("onInput", event);
    },
    addData() {
      console.log("add Data");
    },
    async openform(state) {
      // console.log("openForm: " + state);
      try {
        this.activeFiltersSelected = [];
        this.typeSchema[0].group[0].group[1].group[0].component[0]["class"] =
          "d-none";

        if (state == false) {
          this.formdata = {};
          this.formdatadummy = {};
          this.ListPosition = {};
          this.ListDummy = {};
          this.clean = {};
          this.blnOpenFilter = false;
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }
          await this.processLoadingData();
        }
        //เปิดฟอร์ม
        else if (state == true) {
          this.blnloading = true;
          //กำหนด action insert
          this.listRelate = [];

          this.action = "Insert";
          this.fetchgetworktype();

          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          setTimeout(() => {
            this.blnloading = false;
          }, 600);
        }
        this.blnOpenform = state;
        setTimeout(() => {
          this.formInactive = [];
          let dropdownWork = {
            name: "intWorkTypeID",
            listdropdownitem: this.listworktype,
          };
          this.formInactive.push(dropdownWork);
          // this.$refs.form.$refs.DynamicForm.$refs.intPositionID[0].listdropdownitem = this.listworktype
          // console.log(this.$refs.form.$refs.DynamicForm.$refs.intPositionID[0].listdropdownitem)
        }, 100);
      } catch (error) {
        console.log(error);
      }
    },
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(
        "SelectedListPosition",
        JSON.stringify(SelectedList)
      );
    },
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListPosition"))
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom.scss";
</style>