const apiposition = {
  insertPosition: "/v1/api/AdsPosition/InsertPosition",
  updatePosition: "/v1/api/AdsPosition/UpdatePosition/",
  deletePosition: "/v1/api/AdsPosition/DeletePosition/",
  getPositionList: "/v1/api/AdsPosition/PositionList",
  getPositionByID: "/v1/api/AdsPosition/PositionByID/",
  duplicate: "/v1/api/AdsPosition/DuplicatePosition",
  checkusedstatus: "/v1/api/AdsPosition/CheckUsageStatusPosition/",
  getControlFieldData: "/v1/api/AdsUserTemplate/GetControlFieldData/",
};

export default apiposition;

