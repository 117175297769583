export const typeSchema = [
  {
    group: [
      {
        class: "row m-0 gx-3 p-0",
        group: [
          {
            class: "row m-0 gx-3 p-0",
            group: [
              {
                class: "m-0 gx-3 p-0",
                group: [
                  {
                    class: "col-12 d-flex justify-content-between align-items-center mb-4 p-0",
                    component: [
                      // Position Label
                      {
                        name: "7",
                        label: "1004", //Position information
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle ",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                      },
                    ],
                  },
                  
                ],
              },
              {
                class: "row ",
                group: [{
                  class: "col-12 p-0 mb-4",
                  component: [{
                    strName: "1004",
                    star: "*",
                    type: "Selectdropdown",
                    List: true,
                    state: null,               
                    datavalue: "intWorkTypeID",
                    blnSystemword: false,
                    blnAddress: false,
                    listworktype:  "listworktype",

                    alertMsg: "284",
                    //sub: "1",
                    // altMsgBox: "421",
                  }]
                }]
              },

              {
                class: "row m-0 gx-3 p-0",
                group: [
                  {
                    class: "col-12 d-flex  align-items-center mb-4 p-0",
                    component: [
                      // Position Label
                      {
                        name: "7",
                        label: "1003", //Position information
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle ",
                      },
                     
                    ],
                  },
                ],
              },
              
              {
                class: "row m-0 gx-3 p-0",

                group: [
                  {
                    class: "col-12 mb-3 p-sm-0 p-0",
                    component: [
                      // Position name TH
                      {
                        label: "85",
                        type: "InputText",
                        datavalue: "strPositionNameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpPositionNameTH",
                        validate: "letterTH",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row m-0 gx-3 p-0",

                group: [
                  {
                    class: "col-12 p-0 mb-4 p-sm-0",
                    component: [
                      // Position name
                      {
                        label: "84",
                        type: "InputText",
                        datavalue: "strPositionNameEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpPositionNameEN",
                        validate: "letterEN",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },


              {
                class: "row m-0 gx-3 p-0",

                group: [
                  {
                    class: "col-12 mb-3 p-sm-0 p-0 w-100",
                    component: [
                      // work period
                      {
                        label: '86',
                        year: '91',
                        month: '92',
                        day: '219',
                        type: "InputWorkPeriod",
                        datavalue: "strPositionWorkPeriod",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpPositionWorkPeriodYear",
                        maxvalueyear: 60,
                        maxvaluemonth: 11,
                        maxvalueday: 30,
                        require: true
                      },
                    ],
                  },

                ],
              },
              {
                class: "mb-lg-3 mb-sm-2 p-0 g-0",
                group: [
                  {
                    class: "m-0 p-0",
                    component: [
                      {
                        datavalue: "intPositionStatus",
                        name: "19", // Status
                        type: "Toggle",
                        open: "89", // Active
                        close: "90", // Inactive
                        alertMsg: "410",
                        altMsgBox: "410",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row m-0 gx-3 p-0",

                group: [
                  {
                    class:
                      "modal-footer border-0 justify-content-start pt-0 mt-6 px-0",
                    component: [
                      {
                        type: "Button",
                        name: "36", // OK
                        value: false,
                        state: "submit",
                        class: "btn-fix me-3",
                      },
                      {
                        type: "Button",
                        name: "35", // Cancel
                        value: false,
                        state: "cancel",
                        class: "btn-fix btn-cancel",
                      },
                    ],
                  },
                ],
              },

            ],
          },
          {
            class: "w-100 d-flex justify-content-center",
            group: [
              {
                component: [
                  {
                    class: "mt-3",
                    type: "DeleteinForm",
                    icon: "DeleteFill",
                    classBtnIcon: "fill-secondary",
                    datavalue: "blnDeleteinForm",
                    state: "delete",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export const request = [
  "strPositionNameEN",
  "strPositionNameTH",
  "strPositionWorkPeriod",
  "intWorkTypeID",
];
